import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = (pageContext) => (
  <Layout pageContext={pageContext} variant="404">
    <SEO title="404: Not found" />
  </Layout>
)

export default NotFoundPage
